import React, { useState } from "react";
import { useLazyMint, useContract } from "@thirdweb-dev/react";
import { IpfsImage } from "react-ipfs-image";
import { useNavigate } from "react-router-dom";

const UploadArtForm = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { contract } = useContract(props.contractAddress, "nft-drop");
  const { mutateAsync: lazyMint, isLoading, error } = useLazyMint(contract);
  const handleFileRead = async (files) => {
    const newData = [];
    const newInvalidData = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let content = await file.text();
      content = JSON.parse(content);
      if (!content.name || !content.image || !content.description) {
        newInvalidData.push(content);
      }
      let newobj = {
        name: content.name,
        image: content.image,
        description: content.description,
      };
      newData.push(newobj);
    }
    setData(newData);
  };
  async function Upload() {
    if (data.length === 0) {
      alert("Please Upload Artwork");
      return;
    }
    await lazyMint({ metadatas: data }).then(async (res) => {
      await navigate(`/manage/${props.contractAddress}/3`);
    });
  }
  return (
    <>
      {" "}
      <div className="col-lg-9 ps-lg-5">
        <div className="main_title version_2">
          <span>
            <em />
          </span>
          <h2>Upload Artwork</h2>
        </div>
        <div className="row">
          <div className="form-group">
            <label>Upload ArtWork</label>
            <div className="file_upload">
              <input
                type="file"
                multiple
                onChange={(e) => handleFileRead(e.target.files)}
                accept="application/json"
                id="artwork"
              />
              <i className="bi bi-file-earmark-arrow-up" />
              <div>PNG, GIF, JPG. Max 500Kb</div>
            </div>
          </div>
        </div>
        <div className="pt-4">
          <div className="author_list">
            {data.map((item, index) => {
              return (
                <a className="author">
                  <div className="author_thumb veryfied">
                    <i className="bi bi-check" />
                    <figure>
                      <IpfsImage
                        hash={item.image}
                        className="lazy loaded"
                        width={100}
                        height={100}
                      />
                    </figure>
                  </div>
                  <div>
                    <h6>{item.name}</h6>
                    <span>{item.description}</span>
                  </div>
                </a>
              );
            })}
          </div>
        </div>

        <p className="text-end mt-4">
          <a className="btn_1" onClick={Upload}>
            Next
          </a>
        </p>
      </div>
    </>
  );
};
export default UploadArtForm;
