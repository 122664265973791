import { useParams } from "react-router-dom";
import { useContract } from "@thirdweb-dev/react";
import ListNftForm from "../Components/Manage/ListNftForm";
import SidebarManage from "../Components/SidebarManage";
import Header from "../Components/Header";

const Page4 = () => {
  let { id } = useParams();
  const { contract } = useContract(id, "nft-drop");
  if (!contract) return null;
  return (
    <>
      <Header />
      <div className="container margin_30_250">
        <div className="row">
          <SidebarManage active={4} contractAddress={id} />
          <ListNftForm contractAddress={id} />
        </div>
        {/* /row */}
      </div>
    </>
  );
};

export default Page4;
