import React, { useState } from "react";
import {
  useContract,
  useOwnedNFTs,
  useValidDirectListings,
  useAddress,
} from "@thirdweb-dev/react";
import { NATIVE_TOKEN_ADDRESS } from "@thirdweb-dev/sdk";
import NFT from "./Nft";
import Swal from "sweetalert2";

const ListNftForm = (props) => {
  const address = useAddress();
  const [price, setPrice] = useState(0);
  const [selected, setSelected] = useState([]);
  const { contract } = useContract(props.contractAddress, "nft-drop");
  const { data, isLoading, error } = useOwnedNFTs(contract, address);
  const marketplace = useContract(
    "0x9a9557DeB668dc8a1982688b58cC3118B1574f16",
    "marketplace"
  );

  async function listNft(nftstolist) {
    const isApproved = await contract?.isApproved(
      address,
      "0x9a9557DeB668dc8a1982688b58cC3118B1574f16"
    );
    if (!isApproved) {
      await contract?.setApprovalForAll(
        "0x9a9557DeB668dc8a1982688b58cC3118B1574f16",
        true
      );
    }
    const listings = nftstolist.map((item, index) => {
      return {
        assetContractAddress: props.contractAddress,
        tokenId: item.metadata.id,
        buyoutPricePerToken: price,
        currencyContractAddress: NATIVE_TOKEN_ADDRESS,
        isReservedListing: false,
        quantity: "1",
        startTimestamp: new Date(),
        listingDurationInSeconds: 8640000,
      };
    });
    console.log(listings, marketplace);
    const results = await marketplace.data.direct
      .createListingsBatch(listings)
      .then((res) => {
        Swal.fire({
          title: "Success!",
          text: "Your NFTs have been listed",
          icon: "success",
          confirmButtonText: "Ok",
        });
      });
  }
  if (!contract || !marketplace) return null;
  return (
    <>
      {" "}
      <div className="col-lg-9 ps-lg-5">
        <div className="main_title version_2">
          <span>
            <em />
          </span>
          <h2>List Nfts</h2>
        </div>
        <div className="row">
          <div className="form-group">
            <label>Price</label>
            <input
              type="number"
              className="form-control"
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>

          <p className="text-end">
            <a
              onClick={(e) => {
                e.preventDefault();
                listNft(selected);
              }}
              className="btn_1"
            >
              List
            </a>
          </p>
        </div>

        <div className="pt-4">
          <div className="author_list">
            {data &&
              data.map((item, index) => {
                return (
                  <>
                    <a
                      onClick={() => {
                        if (selected.includes(item)) {
                          const newSelected = selected.filter(
                            (i) => i !== item
                          );

                          setSelected(newSelected);
                        } else {
                          setSelected([...selected, item]);
                        }
                      }}
                    >
                      <NFT
                        item={item}
                        contractAddress={props.contractAddress}
                        select={selected.includes(item) ? true : false}
                      />
                    </a>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
export default ListNftForm;
