import { useValidDirectListings, useContract } from "@thirdweb-dev/react";

const NFT = (props) => {
  console.log(props);
  const { contract } = useContract(
    "0x9a9557DeB668dc8a1982688b58cC3118B1574f16",
    "marketplace"
  );
  const {
    data: directListings,
    isLoading,
    error,
  } = useValidDirectListings(contract, {
    tokenContract: props.contractAddress,
    tokenId: props.item.metadata.id,
  });
  console.log(directListings);
  return (
    <>
      <a
        style={{
          border: props.select ? "2px solid #00b074" : "",
        }}
        className="author"
      >
        <div className="author_thumb veryfied">
          <i className="bi bi-check" />
          <figure>
            <img
              src={props.item.metadata.image}
              className="lazy loaded"
              width={100}
              height={100}
            />
          </figure>
        </div>
        <div>
          <h6>{props.item.metadata.name} </h6>
          <h6>
            {directListings &&
            isLoading === false &&
            directListings.length > 0 ? (
              <span className="badge bg-success">
                LISTED:{" "}
                {Math.floor(
                  directListings[0].currencyValuePerToken.displayValue
                )}{" "}
                MATIC
              </span>
            ) : (
              <span className="badge bg-danger">NOT LISTED</span>
            )}
          </h6>
          <span>{props.item.metadata.description}</span>
        </div>
      </a>
    </>
  );
};
export default NFT;
