import Header from "./Components/Header";
import SidebarManage from "./Components/SidebarManage";
import CreateForm from "./Components/Create/CreateForm";
import Footer from "./Components/Footer"; 

const CreateNewProject = () => {
  return (
    <>
      <Header />
      <div className="container margin_30_40">
        <div className="row">
          <SidebarManage active={7}/>
          <CreateForm />
        </div>
        {/* /row */}
      </div>
      <Footer />
    </>
  );
};
export default CreateNewProject;
