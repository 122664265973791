const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <h3>Marketplace</h3>
            <div className="links">
              <ul>
                <li>
                  <a href="/collections">Collections</a>
                </li>
                <li>
                  <a href="/brands">Brands</a>
                </li>
                <li>
                  <a href="/categories">Categories</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <h3>Useful Links</h3>
            <div className="links">
              <ul>
                <li>
                  <a href="#">Help Center</a>
                </li>

                <li>
                  <a href="#">Faq</a>
                </li>

                <li>
                  <a href="#">Join NftX</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <h3>Socials</h3>
            <div className="links">
              <ul>
                <li>
                  <a href="#">Instagram</a>
                </li>
                <li>
                  <a href="#">Twitter</a>
                </li>
                <li>
                  <a href="#">Discord</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <h3>Keep in touch</h3>
            <div id="newsletter">
              <div id="message-newsletter" />
              <form name="newsletter_form" id="newsletter_form">
                <div className="form-group">
                  <input
                    type="email"
                    name="email_newsletter"
                    id="email_newsletter"
                    className="form-control"
                    placeholder="Your email"
                  />
                  <button type="submit" id="submit-newsletter">
                    <i className="bi bi-chevron-right" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /row*/}
        <hr />
        <div className="row add_bottom_25">
          <div className="col-md-6">
            <ul className="footer-selector clearfix">
              <li>
                <div className="styled-select lang-selector">
                  <select>
                    <option value="English" selected>
                      English
                    </option>
                  </select>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <ul className="additional_links">
              <li>
                <a href="#">Terms and conditions</a>
              </li>
              <li>© 2023 NFTX</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
