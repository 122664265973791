import { Route, BrowserRouter, Routes } from "react-router-dom";
import React from "react";
import CreateNewProject from "./Pages/CreateNewProject";
import CreateNewCategory from "./Pages/CreateNewCategory";
import CreateNewBrand from "./Pages/CreateNewBrand";
import ManagePage from "./Pages/ManagePage";
import Page2 from "./Pages/Manage/Page2";
import Page3 from "./Pages/Manage/Page3";
import Page4 from "./Pages/Manage/Page4";
import Connect from "./Pages/Connect";
import { useAddress } from "@thirdweb-dev/react";

import "./App.css";
function App() {
  const address = useAddress();

  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}

          <Route
            path="/new/project"
            element={address ? <CreateNewProject /> : <Connect />}
          />
          <Route
            path="/new/category"
            element={address ? <CreateNewCategory /> : <Connect />}
          />
          <Route
            path="/new/brand"
            element={address ? <CreateNewBrand /> : <Connect />}
          />
          <Route path="/" element={address ? <ManagePage /> : <Connect />} />
          <Route
            path="/manage/:id/2"
            element={address ? <Page2 /> : <Connect />}
          />
          <Route
            path="/manage/:id/3"
            element={address ? <Page3 /> : <Connect />}
          />
          <Route
            path="/manage/:id/4"
            element={address ? <Page4 /> : <Connect />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
