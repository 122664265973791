const SidebarManage = (props) => {
  return (
    <>
      <div className="col-lg-3">
        <div className="main_profile edit_section">
          <h1>Manage Collections</h1>
          <ul>
            {props.contractAddress && (
              <>
                <li>
                  <a
                    href={`/manage/${props.contractAddress}/1`}
                    className={props.active === 1 ? "active" : ""}
                  >
                    <i className="bi bi-person" />
                    Add Details
                  </a>
                </li>
                <li>
                  <a
                    href={`/manage/${props.contractAddress}/2`}
                    className={props.active === 2 ? "active" : ""}
                  >
                    <i className="bi bi-file-earmark-arrow-up" />
                    Upload Artwork
                  </a>
                </li>
                <li>
                  <a
                    href={`/manage/${props.contractAddress}/3`}
                    className={props.active === 3 ? "active" : ""}
                  >
                    <i className="bi bi-gear" />
                    Mint NFTs
                  </a>
                </li>
                <li>
                  <a
                    href={`/manage/${props.contractAddress}/4`}
                    className={props.active === 4 ? "active" : ""}
                  >
                    <i className="bi bi-gear" />
                    List NFTs
                  </a>
                </li>
              </>
            )}
            <li>
              <a
                href="/new/project"
                className={props.active === 7 ? "active" : ""}
              >
                <i className="bi bi-gear" />
                Add Project
              </a>
            </li>
            <li>
              <a
                href="/new/category"
                className={props.active === 5 ? "active" : ""}
              >
                <i className="bi bi-gear" />
                Add Category
              </a>
            </li>

            <li>
              <a
                href="/new/brand"
                className={props.active === 6 ? "active" : ""}
              >
                <i className="bi bi-gear" />
                Add Brand
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default SidebarManage;
