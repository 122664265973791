import React, { useEffect, useState } from "react";
import Header from "./Components/Header";
import SidebarManage from "./Components/SidebarManage";
import Footer from "./Components/Footer"; 
import { Web3Storage } from "web3.storage";

const ManagePage = () => {
  const [projects, setProjects] = useState([]);
  const client = new Web3Storage({
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweEY1OTQxZmJmQjI2ZWQ3NDBhRTBkNEJDZjVkOWJmRjM4QTE4NUVEZTgiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2ODQyNDgxMDg2MTksIm5hbWUiOiJkZW1vIGFwcCJ9.mxgK2psVn0xIKgFOj7zLxjip_tjp2dSK64x6vqEp3YQ",
  });
  useEffect(() => {
    async function fetchData() {
      await fetch("https://corsproxy.io/?https://nftx-frontend-api.supermint.tech/api/get/projects").then(
        async (res) => {
          const data = await res.json();

          setProjects(data.projects);
        }
      );
    }
    fetchData();
    console.log(projects);
  }, []);
  return (
    <>
      <Header />
      <div className="container margin_30_250">
        <div className="row">
          <SidebarManage active={1} />
          <div className="col-lg-9 ps-lg-5">
            <div className="main_title version_2">
              <span>
                <em />
              </span>
              <h2>Manage Projects</h2>
            </div>

            <div className="pt-4">
              <div className="author_list">
                {projects &&
                  projects.map((item) => {
                    return (
                      <a
                        className="author"
                        href={`/manage/${item.blockchainContract}/2`}
                      >
                        <div className="author_thumb veryfied">
                          <i className="bi bi-check" />
                          <figure>
                            <img
                              src={
                                projects &&
                                `https://${item.cid}.ipfs.w3s.link/${item.logo}`
                              }
                              id="logo"
                              className="lazy loaded"
                              width={100}
                              height={100}
                            />
                          </figure>
                        </div>
                        <div>
                          <h6>{item.name}</h6>

                          <span>{item.description}</span>
                        </div>
                      </a>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        {/* /row */}
      </div>
      <Footer />
    </>
  );
};

export default ManagePage;
