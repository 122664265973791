import { useState } from "react";
import { Web3Storage } from "web3.storage";
import Swal from "sweetalert2";

const CreateCategoryForm = () => {
  const [state, setState] = useState({});
  const client = new Web3Storage({
    token: process.env.REACT_APP_WEB3STORAGE_API_KEY,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  async function addCategory() {
    let logo = document.getElementById("logo");
    let banner = document.getElementById("banner");
    const files = [logo.files[0], banner.files[0]];
    const rootCid = await client.put(files, {
      name: state.name,
      maxRetries: 3,
    });
    console.log(rootCid);
    setState((prevState) => ({
      ...prevState,
      cid: rootCid,
    }));
    await fetch("https://corsproxy.io/?https://nftx-api.supermint.tech/api/add/category", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify({
        name: state.name,
        description: state.description,
        logo: state.logo.slice(12),
        banner: state.banner.slice(12),
        cid: rootCid,
      }),
    }).then(async (res) => {
      const data = await res.json();
      console.log(data);
      if (data.message === "Added New Category") {
        Swal.fire({
          title: "Success",
          text: "Category Added Successfully",
          icon: "success",
          confirmButtonText: "Ok",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Something Went Wrong",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    });
  }
  return (
    <>
      <div className="col-lg-9 ps-lg-5">
        <div className="main_title version_2">
          <span>
            <em />
          </span>
          <h2>Add New Category</h2>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="form-group">
              <label>Upload Logo</label>
              <div className="file_upload">
                <input
                  type="file"
                  id="logo"
                  name="logo"
                  onChange={handleChange}
                />
                <i className="bi bi-file-earmark-arrow-up" />
                <div>Selected {state.logo ? state.logo.slice(12) : "none"}</div>
                <div>PNG, GIF, JPG. Max 500Kb</div>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="form-group">
              <label>Upload Banner</label>
              <div className="file_upload">
                <input
                  type="file"
                  id="banner"
                  name="banner"
                  onChange={handleChange}
                />
                <i className="bi bi-file-earmark-arrow-up" />
                <div>
                  Selected {state.banner ? state.banner.slice(12) : "none"}
                </div>
                <div>PNG, GIF, JPG. Max 1MB</div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Category Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="e.g. Abstract modern art"
                name="name"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Category Description</label>
              <input
                type="text"
                className="form-control"
                placeholder="e.g. Abstract modern art"
                name="description"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        {/* /row */}
        <p className="text-end mt-4">
          <a className="btn_1" onClick={addCategory}>
            Add
          </a>
        </p>
      </div>
    </>
  );
};

export default CreateCategoryForm;
