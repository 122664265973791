import { useParams } from "react-router-dom";
import { useContract } from "@thirdweb-dev/react";
import { useState } from "react";
import UploadArtForm from "../Components/Manage/UploadArtForm";
import SidebarManage from "../Components/SidebarManage";
import Header from "../Components/Header";

const Page2 = () => {
  const [data, setData] = useState([]);
  let { id } = useParams();
  const { contract } = useContract(id, "nft-drop");
  if (!contract) return null;
  return (
    <>
      <Header />
      <div className="container margin_30_250">
        <div className="row">
          <SidebarManage active={2} contractAddress={id}/>
          <UploadArtForm contractAddress={id} />
        </div>
        {/* /row */}
      </div>
    </>
  );
};

export default Page2;
