import React, { useState } from "react";
import {
  useContract,
  useUnclaimedNFTs,
  useUnclaimedNFTSupply,
  useSetClaimConditions,
  useAddress,
} from "@thirdweb-dev/react";
import { useNavigate } from "react-router-dom";
import { BigNumber } from "ethers";

const MintNftForm = (props) => {
  const address = useAddress();
  const navigate = useNavigate();
  const { contract } = useContract(props.contractAddress, "nft-drop");
  const unclaimedSupply = useUnclaimedNFTSupply(contract);
  const { data, isLoading, error } = useUnclaimedNFTs(contract);
  const {
    mutateAsync: setClaimConditions,
    isLoading1,
    error1,
  } = useSetClaimConditions(contract);
  async function mintNFT() {
    await setClaimConditions({
      phases: [
        {
          currencyAddress: "0x0000000000000000000000000000000000001010",
          price: 1000,
          maxClaimablePerWallet: 0, // The maximum number of tokens a wallet can claim
          maxClaimableSupply: 100000000000, // The total number of tokens that can be claimed in this phase
          startTime: new Date(), // When the phase starts (i.e. when users can start claiming tokens)
          snapshot: [
            {
              address: address, // The address to snapshot
              maxClaimable: 1000000,
              price: 0,
            },
          ],
          merkleRootHash: "0x...", // The merkle root hash of the snapshot
        },
      ],
    }).then(async (res) => {
      await contract.erc721
        .claim(BigNumber.from(unclaimedSupply.data))
        .then((res) => {
          navigate(`/manage/${props.contractAddress}/4`);
        });
    });
  }
  return (
    <>
      {" "}
      <div className="col-lg-9 ps-lg-5">
        <div className="main_title version_2">
          <span>
            <em />
          </span>
          <h2>Mint NFTs To Wallet</h2>
        </div>
        <div className="pt-4">
          <div className="author_list">
            {data &&
              data.map((item, index) => {
                return (
                  <a className="author">
                    <div className="author_thumb veryfied">
                      <i className="bi bi-check" />
                      <figure>
                        <img
                          src={item.image}
                          className="lazy loaded"
                          width={100}
                          height={100}
                        />
                      </figure>
                    </div>
                    <div>
                      <h6>{item.name}</h6>
                      <span>{item.description}</span>
                    </div>
                  </a>
                );
              })}
          </div>
        </div>
        <p className="text-end mt-4">
          <a onClick={mintNFT} className="btn_1">
            Next
          </a>
        </p>
      </div>
    </>
  );
};
export default MintNftForm;
