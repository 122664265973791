import Header from "./Components/Header";
import SidebarManage from "./Components/SidebarManage";
import CreateCategoryForm from "./Components/Category/CreateCategoryForm";
import Footer from "./Components/Footer"; 

const CreateNewProject = () => {
  return (
    <>
      <Header />
      <div className="container margin_30_40">
        <div className="row">
          <SidebarManage active={5}/>
          <CreateCategoryForm />
        </div>
        {/* /row */}
      </div>
      <Footer />
    </>
  );
};
export default CreateNewProject;
