import { ConnectWallet } from "@thirdweb-dev/react";

const Connect = () => {
  return (
    <>
      <div className="container margin_30_40">
        <div className="row">
        
          <div className="col-md- col-md-offset-3 text-center">
          <ConnectWallet />
       
       </div>
        </div>
      </div>
    </>
  );
};

export default Connect;
