import { useState } from "react";
import { Web3Storage } from "web3.storage";
import Swal from "sweetalert2";

const CreateBrandForm = () => {
  const [state, setState] = useState({});
  const client = new Web3Storage({
    token: process.env.REACT_APP_WEB3STORAGE_API_KEY,
  });
  const handleChange = (e) => {
    let { name, value, files } = e.target;
    console.log(files);
    if (name === "images" || name === "videos") {
      let arr = [];
      for (let i = 0; i < files.length; i++) {
        arr.push(files[i].name);
      }
      value = arr;
      console.log(value);
    }

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  async function addBrand() {
    console.log("deploying", state);
    let logo = document.getElementById("logo");
    let banner = document.getElementById("banner");
    let images = document.getElementById("images");
    let videos = document.getElementById("videos");
    console.log(images.files);
    const files = [logo.files[0], banner.files[0]];
    for (let i = 0; i < images.files.length; i++) {
      files.push(images.files[i]);
    }
    for (let i = 0; i < videos.files.length; i++) {
      files.push(videos.files[i]);
    }

    const rootCid = await client.put(files, {
      name: state.name,
      maxRetries: 3,
    });
    console.log(rootCid);
    setState((prevState) => ({
      ...prevState,
      cid: rootCid,
    }));
    await fetch("https://corsproxy.io/?https://nftx-api.supermint.tech/api/add/brand", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify({
        name: state.name,
        description: state.description,
        cid: rootCid,
        logo: state.logo.slice(12),
        banner: state.banner.slice(12),
        images: state.images,
        videos: state.videos,
        history: state.history,
        twitter: state.twitter,
        instagram: state.instagram,
        facebook: state.facebook,
        website: state.website,
      }),
    }).then(async (res) => {
      const data = await res.json();
      console.log(data);
      if (data.message === "Added New Brand") {
        Swal.fire({
          title: "Success!",
          text: "Brand Added Successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "Something Went Wrong",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    });
  }
  return (
    <>
      <div className="col-lg-9 ps-lg-5">
        <div className="main_title version_2">
          <span>
            <em />
          </span>
          <h2>Add New Brand</h2>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="form-group">
              <label>Upload Logo</label>
              <div className="file_upload">
                <input
                  onChange={handleChange}
                  type="file"
                  id="logo"
                  name="logo"
                />
                <i className="bi bi-file-earmark-arrow-up" />
                <div>Selected {state.logo ? state.logo.slice(12) : "none"}</div>
                <div>PNG, GIF, JPG. Max 500Kb</div>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="form-group">
              <label>Upload Banner</label>
              <div className="file_upload">
                <input
                  onChange={handleChange}
                  type="file"
                  id="banner"
                  name="banner"
                />
                <i className="bi bi-file-earmark-arrow-up" />
                <div>
                  Selected {state.banner ? state.banner.slice(12) : "none"}
                </div>
                <div>PNG, GIF, JPG. Max 1MB</div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Category Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="e.g. Abstract modern art"
                name="name"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Category Description</label>
              <input
                type="text"
                className="form-control"
                placeholder="e.g. Abstract modern art"
                name="description"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Twitter</label>
              <input
                type="text"
                className="form-control"
                placeholder="e.g. Abstract modern art"
                name="twitter"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Instagram</label>
              <input
                type="text"
                className="form-control"
                placeholder="e.g. Abstract modern art"
                name="instagram"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Facebook</label>
              <input
                type="text"
                className="form-control"
                placeholder="e.g. Abstract modern art"
                name="facebook"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Website</label>
              <input
                type="text"
                className="form-control"
                placeholder="e.g. Abstract modern art"
                name="website"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Upload images</label>
              <div className="file_upload">
                <input
                  id="images"
                  name="images"
                  onChange={handleChange}
                  type="file"
                  multiple
                />
                <i className="bi bi-file-earmark-arrow-up" />
                <div>
                  Selected {state.images ? state.images.slice(12) : "none"}
                </div>
                <div>PNG, GIF, JPG. Max 500Kb</div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Upload videos</label>
              <div className="file_upload">
                <input
                  id="videos"
                  name="videos"
                  onChange={handleChange}
                  type="file"
                  multiple
                />
                <i className="bi bi-file-earmark-arrow-up" />
                <div>
                  Selected {state.videos ? state.videos.slice(12) : "none"}
                </div>
                <div>PNG, GIF, JPG. Max 1MB</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group">
            <label>History (About Brand)</label>
            <textarea
              className="form-control"
              rows={5}
              name="history"
              onChange={handleChange}
            />
          </div>
        </div>
        {/* /row */}
        <p className="text-end mt-4">
          <a className="btn_1" onClick={addBrand}>
            Add
          </a>
        </p>
      </div>
    </>
  );
};

export default CreateBrandForm;
