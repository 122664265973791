import {
  useSDK,
  useAddress,
  useNetwork,
  useNetworkMismatch,
  useMetamask,
} from "@thirdweb-dev/react";
import { Web3Storage } from "web3.storage";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CreateForm = () => {
  const navigate = useNavigate();
  const sdk = useSDK();
  const address = useAddress();
  const misMatch = useNetworkMismatch();
  const [, setNetwork] = useNetwork();
  const connectWithMetamask = useMetamask();
  const [state, setState] = useState({});
  const client = new Web3Storage({
    token: process.env.REACT_APP_WEB3STORAGE_API_KEY,
  });
  useEffect(() => {
    async function fetchData() {
      await fetch("http://localhost:3002/api/get/brands", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (res) => {
          let data = await res.json();

          setState((prevState) => ({
            ...prevState,
            brands: data.brands,
            brand_name: data.brands[0].name,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
      await fetch("http://localhost:3002/api/get/categories", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (res) => {
          let data = await res.json();

          setState((prevState) => ({
            ...prevState,
            categories: data.categories,
            category: data.categories[0].name,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);
  const handleChange = (e) => {
    let { name, value, files } = e.target;

    if (name === "images" || name === "videos") {
      let arr = [];
      for (let i = 0; i < files.length; i++) {
        arr.push(files[i].name);
      }
      value = arr;
    }

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  async function deployContract() {
    console.log("deploying", state.brand_name);
    let logo = document.getElementById("logo");
    let banner = document.getElementById("banner");
    let brouchure = document.getElementById("brouchure");
    let whitepaper = document.getElementById("whitepaper");
    let images = document.getElementById("images");
    let videos = document.getElementById("videos");
    const fileInput = document.querySelector('input[type="file"]');
    const files = [
      logo.files[0],
      banner.files[0],
      brouchure.files[0],
      whitepaper.files[0],
    ];
    for (let i = 0; i < images.files.length; i++) {
      files.push(images.files[i]);
    }
    for (let i = 0; i < videos.files.length; i++) {
      files.push(videos.files[i]);
    }

    const rootCid = await client.put(files, {
      name: state.name,
      maxRetries: 3,
    });
    console.log(rootCid);
    setState((prevState) => ({
      ...prevState,
      cid: rootCid,
    }));
    if (misMatch) {
      setNetwork?.("mumbai");

      return;
    }
    if (!address) {
      connectWithMetamask();

      return;
    }
    console.log("deploying", state.brand_name);

    let contract = await sdk.deployer
      .deployNFTDrop({
        name: state.name,
        primary_sale_recipient: address,
        description: state.description,
        symbol: state.symbol,
      })
      .then(async (success, error) => {
        console.log("suc", success, "Err", error);
        if (success) {
          await fetch("https://corsproxy.io/?https://nftx-api.supermint.tech/api/add/project", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: process.env.REACT_APP_API_KEY,
            },
            body: JSON.stringify({
              name: state.name,
              description: state.description,
              logo: state.logo.slice(12),
              banner: state.banner.slice(12),
              brouchure: state.brouchure.slice(12),
              whitepaper: state.whitepaper.slice(12),
              images: state.images,
              videos: state.videos,
              symbol: state.symbol,
              cid: rootCid,
              blockchainContract: success,
              twitter: state.twitter,
              facebook: state.facebook,
              website: state.website,
              instagram: state.instagram,
              history: state.history,
              brand: state.brand_name,
              category: state.category,
            }),
          }).then(async (res) => {
            let response = await res.json();
            console.log(response);
            if (response.message === "Added New Project") {
              navigate(`/manage/${success}/2`);
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      });
  }
  if (!state.brands || !state.categories) return <div>Loading...</div>;
  return (
    <>
      <div className="col-lg-9 ps-lg-5">
        <div className="main_title version_2">
          <span>
            <em />
          </span>
          <h2>Add New Project</h2>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="form-group">
              <label>Upload Logo</label>
              <div className="file_upload">
                <input
                  type="file"
                  name="logo"
                  id="logo"
                  onChange={handleChange}
                />
                <i className="bi bi-file-earmark-arrow-up" />
                <div>Selected {state.logo ? state.logo.slice(12) : "none"}</div>
                <div>PNG, GIF, JPG. Max 500Kb</div>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="form-group">
              <label>Upload Banner</label>
              <div className="file_upload">
                <input
                  type="file"
                  name="banner"
                  id="banner"
                  onChange={handleChange}
                />
                <i className="bi bi-file-earmark-arrow-up" />
                <div>
                  Selected {state.banner ? state.banner.slice(12) : "none"}
                </div>
                <div>PNG, GIF, JPG. Max 1MB</div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Project Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="e.g. Abstract modern art"
                name="name"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Project Description</label>
              <input
                type="text"
                className="form-control"
                placeholder="e.g. Abstract modern art"
                name="description"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Project Brand</label>
              <select
                onChange={handleChange}
                name="brand_name"
                id="brand_name"
                className="form-control"
              >
                {state.brands.length > 0 &&
                  state.brands.map((brand) => (
                    <option value={brand.name}>{brand.name}</option>
                  ))}
              </select>
            </div>{" "}
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Project Category</label>
              <select
                onChange={handleChange}
                name="category"
                id="category"
                className="form-control"
              >
                {state.categories.length > 0 &&
                  state.categories.map((category) => (
                    <option value={category.name}>{category.name}</option>
                  ))}
              </select>
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Instagram</label>
              <input
                type="text"
                className="form-control"
                placeholder="e.g. Abstract modern art"
                name="instagram"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Twitter</label>
              <input
                type="text"
                className="form-control"
                placeholder="e.g. Abstract modern art"
                name="twitter"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Facebook</label>
              <input
                type="text"
                className="form-control"
                placeholder="e.g. Abstract modern art"
                name="facebook"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Website</label>
              <input
                type="text"
                className="form-control"
                placeholder="e.g. Abstract modern art"
                name="website"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group">
            <label>Project History</label>
            <textarea
              className="form-control"
              rows="5"
              name="history"
              onChange={handleChange}
            ></textarea>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Upload Brouchure</label>
              <div className="file_upload">
                <input
                  id="brouchure"
                  name="brouchure"
                  onChange={handleChange}
                  type="file"
                />
                <i className="bi bi-file-earmark-arrow-up" />
                <div>
                  Selected{" "}
                  {state.brouchure ? state.brouchure.slice(12) : "none"}
                </div>
                <div>PNG, GIF, JPG. Max 500Kb</div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Upload Whitepaper</label>
              <div className="file_upload">
                <input
                  id="whitepaper"
                  name="whitepaper"
                  onChange={handleChange}
                  type="file"
                />
                <i className="bi bi-file-earmark-arrow-up" />
                <div>
                  Selected{" "}
                  {state.whitepaper ? state.whitepaper.slice(12) : "none"}
                </div>
                <div>PNG, GIF, JPG. Max 1MB</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Upload images</label>
              <div className="file_upload">
                <input
                  id="images"
                  name="images"
                  onChange={handleChange}
                  type="file"
                  multiple
                />
                <i className="bi bi-file-earmark-arrow-up" />
                <div>
                  Selected {state.images ? state.images.slice(12) : "none"}
                </div>
                <div>PNG, GIF, JPG. Max 500Kb</div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Upload videos</label>
              <div className="file_upload">
                <input
                  id="videos"
                  name="videos"
                  onChange={handleChange}
                  type="file"
                  multiple
                />
                <i className="bi bi-file-earmark-arrow-up" />
                <div>
                  Selected {state.videos ? state.videos.slice(12) : "none"}
                </div>
                <div>PNG, GIF, JPG. Max 1MB</div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Upload Extra Images</label>
              <div className="file_upload">
                <input type="file" />
                <i className="bi bi-file-earmark-arrow-up" />
                <div>PNG, GIF, JPG. Max 500Kb</div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Upload Extra Videos</label>
              <div className="file_upload">
                <input type="file" />
                <i className="bi bi-file-earmark-arrow-up" />
                <div>PNG, GIF, JPG. Max 1MB</div>
              </div>
            </div>
          </div>
        </div> */}

        {/* /row */}
        <p className="text-end mt-4">
          <a className="btn_1" onClick={deployContract}>
            Next
          </a>
        </p>
      </div>
    </>
  );
};

export default CreateForm;
