import Header from "./Components/Header";
import SidebarManage from "./Components/SidebarManage";
import CreateBrandForm from "./Components/Brand/CreateBrandForm";
import Footer from "./Components/Footer";
const CreateNewBrand = () => {
  return (
    <>
      <Header />
      <div className="container margin_30_40">
        <div className="row">
          <SidebarManage active={6} />
          <CreateBrandForm />
        </div>
        {/* /row */}
      </div>
      <Footer />
    </>
  );
};
export default CreateNewBrand;
